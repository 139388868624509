﻿import {Injectable, Pipe, PipeTransform} from '@angular/core';
import { AppConfig } from '../providers/app.config';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'format'
})
@Injectable()
export class FormatPipe implements PipeTransform
{
    transform(value: any, type:string): string
    {
        var dateFormat = AppConfig.dateFormat;
        if (type == 'bool')
        {
            
            if (value == true || value == 1 || value == "1" || value == "TRUE" || value == "true")
                return "Yes";
            else
                return "No";
        }
        else if (type == 'date')
        {
            return new DatePipe("en-US").transform(value, "mediumDate");
        }
        else if (type == 'date-time')
        {
            return new DatePipe("en-US").transform(value, "MMM dd, yyyy hh:mm a");
        }
        else if (type == 'date-short')
        {
            //https://www.concretepage.com/angular-2/angular-2-date-pipe-example

            var currDate = new Date();
            var valDate = new Date(value);

            let df = "mediumDate";

            if (currDate.getDate() == valDate.getDate())
            {
                df = "shortTime";
            }
            else if (currDate.getFullYear() == valDate.getFullYear())
            {
                df = "dd MMM";
            }
            return new DatePipe("en-US").transform(value, df);
        }
        else if (type == 'time')
        {
            return new DatePipe("en-US").transform(value, "hh:mm a");
        }
        else if (type == 'integer')
        {
            var num = Number(value);
            return Math.round(num).toString();
        } 
        else
            return value;
    }
}