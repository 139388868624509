import { Injectable } from '@angular/core';

declare var globalConfig: any; 

@Injectable()
export class AppConfig
{
    static get baseUrl()
    {
        return globalConfig.baseUrl;
    }

    static get isApp()
    {
       // return !document.URL.startsWith('http') || (document.URL.indexOf('8100')>0);

        return globalConfig.isApp;
    }

    static get appVersion()
    {
        return globalConfig.appVersion;
    }

    static get dateFormat()
    {
        return globalConfig.dateFormat;
    }

    static get dateFormatDb()
    {
        return globalConfig.dateFormatDb;
    }


    static clientId = '099153c2625149bc8ecb3e85e03f0022';
}
