﻿import { NgModule } from '@angular/core';
import { FilterPipe } from './filter.pipe';
import { YesNoPipe } from './yes-no.pipe';
import { FormatPipe } from './format.pipe';

@NgModule({
    imports: [],
    declarations: [FilterPipe, YesNoPipe, FormatPipe],
    exports: [FilterPipe, YesNoPipe, FormatPipe]
})

export class PipesModule {}
